export default class Util {
    constructor() 
    {
        window.Util = this
    }

    /** 
    * Dom
    */

    qs(selector, parentNode = document)
    {
        if(typeof parentNode !== 'object') return false
        const node = parentNode.querySelector(selector)
        if(node === null) return false
        return node 
    }

    qsa(selector, parentNode = document) 
    {
        if(typeof parentNode !== 'object') return false
        return [...parentNode.querySelectorAll(selector)]
    }

    createElement(type, options = {}) {
        const ele = document.createElement(type)

        for (let prop in options) {
            switch(true) {
                // is class
                case (prop === 'class'):
                    if(Array.isArray(options[prop])) {
                        options[prop].forEach(name => ele.classList.add(name) )
                        break
                    } 
                    ele.classList.add(options[prop])
                    break
                // is dataset
                case (prop === 'dataset'):
                    if(typeof options[prop] === 'object' && options[prop] !== null) {
                        for(let key in options[prop]) {
                            ele.dataset[key] = options[prop][key]
                        }
                    }
                    break
                case (prop === 'innerText'):
                    ele.innerText = options[prop]
                    break
                // is attribute 
                default:
                    ele.setAttribute(prop, options[prop])
                break
            }
        }
        return ele
    }

    getSiblings(ele) {
        // for collecting siblings
        let siblings = []

        // if no parent, return no sibling
        if(!ele.parentNode) {
            return siblings
        }
    
        // first child of the parent node
        let sibling  = ele.parentNode.firstChild

        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== ele) {
                siblings.push(sibling)
            }
            sibling = sibling.nextSibling
        }
        return siblings
    }

    getPrevSiblings(ele, nodeName=false) {
        let arr = []

        while (ele = ele.previousElementSibling) {
            if(!nodeName || ele.nodeName == nodeName) {
                arr.push(ele)
            }
        }
        return arr;
    }

    getNextSiblings(ele, nodeName=false) {
        let arr = []

        while (ele = ele.nextElementSibling) {
            if(!nodeName || ele.nodeName == nodeName) {
                arr.push(ele)
            }
        }
        return arr;
    }
    /**
    * Barba
    */

    barbaNextDom() {
        let dom = document
    
        if(typeof barba !== 'undefined' && barba.data.next.container !== null) dom = barba.data.next.container
    
        return dom
    }

    setRootName(name)
    {
        return {
            name: name,
            class: '.' + name,
            classPrefix: '.' + name + '__',
            id: '#' + name,
            idPrefix: '#' + name + '__',
        }
    }

    getCenterBetweenElements(parent,child)
    {
        let parentBB = parent.getBoundingClientRect()
        let childBB = child.getBoundingClientRect()
    
        return {
            x: (parentBB.x + (parentBB.width / 2)) - (childBB.x + (childBB.width / 2)),
            y: (parentBB.y + (parentBB.height / 2)) - (childBB.y + (childBB.height / 2))
        }
    }

    getScaleBetweenElements(parent,child, margin = {x:0, y: 0}) 
    {
        let parentBB = parent.getBoundingClientRect()
        let childBB = child.getBoundingClientRect()
        // remove margin
        parentBB.width = parentBB.width - margin.x
        parentBB.height = parentBB.height - margin.y

        let originX = (childBB.x - parentBB.x + (childBB.width / 2)).toFixed(10)
        let originY = (childBB.y - parentBB.y + (childBB.height / 2)).toFixed(10)

        let scaleX = parentBB.width / childBB.width
        let scaleY = parentBB.height / childBB.height

        return {
            orgin: originX + 'px ' + originY + 'px',
            value: Math.min(scaleX, scaleY)
        }
    }
    
    // getMaxHeight(eleArr)
    // {
    //     let height = 0
    //     eleArr.forEach(ele => { 
    //         if(ele.clientWidth > height) height = ele.clientHeight
    //     })
    //     return height
    // }

    inActiveTimeout(timeInSeconds, inActiveCallback, goActiveCallback = ()=> {})
    {
        let timeout
        setup()

        function setup() {
            window.addEventListener('mousemove', resetTimer, false)
            window.addEventListener('mousedown', resetTimer, false)
            window.addEventListener('keypress', resetTimer, false)
            window.addEventListener('DOMMouseScroll', resetTimer, false)
            window.addEventListener('mousewheel', resetTimer, false)
            window.addEventListener('touchmove', resetTimer, false)
            window.addEventListener('MSPointerMove', resetTimer, false)
         
            startTimer()
        }

        function startTimer() {
            // wait 2 seconds before calling goInactive
            timeout = window.setTimeout(goInactive, timeInSeconds * 1000)
        }

        function resetTimer(e) {
            // console.log('clear timeout')
            window.clearTimeout(timeout)
         
            goActive()
        }

        function goInactive() {
            // do something
            inActiveCallback()
        }
         
        function goActive() {
            // do something
            startTimer()
            goActiveCallback()
        }
    }

    // stringToBoolean(stringValue) {
    //     switch(stringValue?.toLowerCase()?.trim()){
    //         case "true": 
    //         case "yes": 
    //         case "1": 
    //           return true;
    
    //         case "false": 
    //         case "no": 
    //         case "0": 
    //         case null: 
    //         case undefined:
    //           return false;
    
    //         default: 
    //           return JSON.parse(stringValue);
    //     }
    // }

    // Click or Tap
    onClickOrTap(elem, callback, preventDefault = false)
    {
        // Make sure a callback is provided
        if ( !callback || typeof(callback) !== 'function' ) return

        // Vars
        let isTouch, startX, startY, distX, distY

        let onTouchStartEvent = event => {
            if(preventDefault) event.preventDefault()
            // Disable click event
            isTouch = true
    
            // Get the starting location and time when finger first touches surface
            startX = event.changedTouches[0].pageX
            startY = event.changedTouches[0].pageY
        }
    
        let onTouchEndEvent = event => {
            if(preventDefault) event.preventDefault()
            // Get the distance travelled and how long it took
            distX = event.changedTouches[0].pageX - startX
            distY = event.changedTouches[0].pageY - startY
    
            // If a swipe happened, do nothing
            if ( Math.abs(distX) >= 7 || Math.abs(distY) >= 10 ) return
    
            // Run callback
            callback(event)
    
        }
    
        let onClickEvent = event => {
            if(preventDefault) event.preventDefault()
            // If touch is active, reset and bail
            if ( isTouch ) {
                isTouch = false
                return
            }
    
            // Run our callback
            callback(event)
        }
    
        // Event listeners
        elem.addEventListener('touchstart', onTouchStartEvent, false)
        elem.addEventListener('touchend', onTouchEndEvent, false)
        elem.addEventListener('click', onClickEvent, false)
    }
    
}

new Util()
