(function ($) {
    class Session {
        constructor() {
            // options
            this.commentsTick = 60

            this.section = $('section.session')
            if(!this.section) return

            this.$ = {}

            this.setupForm()
            this.setupComments()
			this.loadComments()

            
        }

        setupForm()
        {
            let instance = this

            this.$.form = {}
            this.$.form.formEle = this.section.find('.session__form')
            this.$.form.clear = this.section.find('.session__form .text-link')

            this.$.form.formEle.on('submit', function (e) {
                let dataString = $(this).serialize()
                e.preventDefault()

                // $(function() {
                    console.log(instance)
                    $.ajax({
                      type: 'POST',
                      url: '/wp-content/themes/quarterly-webinars/inc/addcomment.php',
                      data: dataString,
                      success: function () {
                        $('#comment').val('')
                        instance.loadComments()
                        // console.log(this)
                      }
                    })
                   
                // })
            })

            this.$.form.clear.on('click', e => {
                $('#comment').val('')
                $('#comment').get(0).set()
            })
        }

        setupComments()
        {
            this.$.comments = {}
            this.$.comments.container = this.section.find('.session__comments')
            this.$.comments.inner = this.section.find('.session__comments-inner')
            this.$.comments.overflow = this.section.find('.session__comments-overflow')

            setInterval(()=> this.loadComments(), this.commentsTick * 1000)
        }

        loadComments()
        {
            //console.log('load comments')
            let id = this.$.comments.container.data('id')
            this.$.comments.inner.load("/wp-content/themes/quarterly-webinars/inc/comments.php?id=" + id)
        }
    }

    new Session()


})(jQuery);