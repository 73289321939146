(function ($) {
    var checkbox = $('#feedback_toggle');
    var feedback = $('.feedback');
    var toggle = feedback.find('[for="feedback_toggle"');
    var container = feedback.find('.feedback__container');

    function anim() {
        var tl = gsap.timeline({
            paused: true,
            onStart: ()=> {
                document.body.classList.add('feedback-active')
            },
            onReverseComplete: ()=> {
                document.body.classList.remove('feedback-active')
            }
        });

        gsap.set(feedback, {xPercent: 0});
        // tl.set(feedback, { zIndex: 20});
        tl.to(feedback, {xPercent: -100});

        if(checkbox.length) checkbox[0].animation = tl;
    }

    if(feedback.length) {
        anim();
    }

    checkbox.on('change',function(){
        var checked = $(this).prop('checked');
        checked ? this.animation.play(): this.animation.reverse();
    });

})(jQuery);