(function ($) {
    var checkbox = $('#menu_toggle');
    var toggle = $('.menu-toggle').find('.menu-toggle__inner');
    var header = $('.header');
    var nav = $('.nav');

    function anim__toggle(ele) {
        var tl = gsap.timeline({});

        gsap.set(ele, { rotateY: 0})
        tl
            .to(ele, { rotateY: -180}, 0)
            .to(ele, { duration: 0.25, scale:1.02, repeat:1, yoyo: true}, 0)
            .to(ele, { duration: 0.25, y: -3, repeat:1, yoyo: true}, 0)
        return tl;
    }

    function anim__navSlide(ele) {
        var inner = ele.find('.nav__inner');
        var tl = gsap.timeline({});

        gsap.set(ele, { display: 'none'})
        gsap.set(inner, { xPercent: -100})
        tl
            .set(ele, { display: 'block'})
            .to(inner, { xPercent: 0})
        return tl;
    }
    function anim__logo(ele) {
        var svg = ele.find('.logo').children();
        var tl = gsap.timeline({});
        tl
            .to(svg, { fill: '#fff'})
        return tl;
    }
    function anim__btn(ele) {
        var btn = ele.find('.drop-menu .btn');
        var tl = gsap.timeline({});
        tl
            .to(btn, { color: '#fff'});
        return tl;
    }

    if(checkbox.length) {
        var tl = gsap.timeline({ 
            paused:true,
            onStart: ()=> {
                document.body.classList.add('menu-active')
            },
            onReverseComplete: ()=> {
                document.body.classList.remove('menu-active')
            }
        });
        tl
            .set('html', { overflow: 'hidden' })
            .add( anim__toggle(toggle), 0 )
            .add( anim__navSlide(nav), 0 )
            .add( anim__logo(header), 0 )
            .add( anim__btn(header), 0.2 )

        checkbox[0].animation = tl;

        // fixes bug - checkbox checked on back
        $(document).on('ready', function(){
            checkbox.prop('checked', false);
        });
    }

    checkbox.on('change',function(){
        var checked = $(this).prop('checked');
        checked ? this.animation.play(): this.animation.reverse();
    });

})(jQuery);