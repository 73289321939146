import Modal from '../_utils/modal'


// handles map UI
export default class SiteModal extends Modal {
    constructor()
    {
        super()

        this.init()
    }

    animation()
    {
        this.on('beforeOpen', ()=> {
            gsap.set(this.content, {opacity: 0})
            gsap.set(this.magnificPopup.instance.bgOverlay, {opacity: 0})
        })

        this.on('open', ()=> {
            let timeline = gsap.timeline()
                gsap.set(this.content, {
                    x: (this.pointer.x - this.content.getBoundingClientRect().left) - (this.content.getBoundingClientRect().width / 2),
                    y: (this.pointer.y - this.content.getBoundingClientRect().top) - (this.content.getBoundingClientRect().height / 2)
                })
                gsap.set(this.content, {scale: 0})
            
            timeline
                .to(this.magnificPopup.instance.bgOverlay, {opacity: 1})
                .to(this.magnificPopup.instance.content, {scale: 1, opacity: 1, x: 0, y: 0}, '-=0.4')
        })

        this.on('beforeClose', ()=> {
            let timeline = gsap.timeline()

            timeline
                .to(this.magnificPopup.instance.content, {
                    // scale: 0, 
                    opacity: 0,
                    // y: 300,
                }, '0')
                .to(this.magnificPopup.instance.bgOverlay , 0.2, {opacity: 0}, '0')
                .set(this.magnificPopup.instance.content, {
                    x: 0,
                    y: 0
                })
        })
        this.on('close', ()=> {
            gsap.set(this.magnificPopup.instance.content, {clearProps: 'all'})
        })
    }

    mouseHandler() 
    {
        this.pointer = {}

        window.addEventListener( 'mousemove', e => {
            this.pointer.x = e.clientX
            this.pointer.y = e.clientY
        })
    }

    init()
    {
        this.clickedEle = false
        this.links = Util.qsa('.modal', this.dom)

        this.mouseHandler()
        this.linkHandler()
        this.animation()
        this.checkHash()
    }

    linkHandler()
    {
        this.links.forEach(ele => {
            Util.onClickOrTap(ele, ()=> {
                this.actonHandler(ele)
            }, true)
        })
    }

    actonHandler(ele)
    {
        let href = this.hrefFilter(ele.href)

        // container on page
        if(href.startsWith('#')) {
            let elem = Util.qs(href)
            if(!elem) {
                console.error('element on page does not exist')
                return
            }

            this.content = elem
            this.open()
        }

        // // container on another page 
        if(href.startsWith('/')) {
            console.log('element on another page')
        }

        // external url
        if(href.startsWith('http')) {
            console.log('external url')
        }

    }

    hrefFilter(href)
    {
        let str = href

        if(str.startsWith('/') || str.indexOf(window.location.origin) >= 0) {
            // internal link

            str = str.replace(window.location.search, '') // filter out search 
            str = str.replace(window.location.origin, '') // filter out origin
            str = str.replace(window.location.pathname, '') // filter out path  
            
            if(!str.startsWith('#') && !str.startsWith('/') && str.length > 0) str = '/' + str

        } else {
            // external link

        }

        return str
    }

    checkHash()
    {
        this.on('open', ()=> {
            window.location.hash = this.content.id
        })

        this.on('close', ()=> {
            history.replaceState(null, null, ' ')
        })

        this.on('ready', ()=> {
            this.hashHandler()
        })

        window.addEventListener('popstate', e=> {
            this.hashHandler()
        })
    }

    hashHandler()
    {
        if(window.location.hash) {
            let hashElement = Util.qs(window.location.hash)
            if(hashElement && hashElement.classList.contains('mfp-hide')) {
                this.content = hashElement
                this.open()
            }
        } else {
            this.close()
        }
    }
}

new SiteModal
