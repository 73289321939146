class EventDay {
    constructor() {
        this.section = Util.qs('section.event-day')
        if(!this.section) return

        this.init()
    }

    init()
    {
        this.animateCTA()
    }

    animateCTA()
    {
        const 
            input = this.section.querySelector('.event-day__col-input'),
            tl = gsap.timeline({paused: 1}),
            col = this.section.querySelector('.event-day__col:nth-of-type(2)'),
            ctawrap = this.section.querySelector('.event-day__cta-wrap'),
            cta = this.section.querySelectorAll('.event-day__cta')

        gsap.set(col, { flexGrow: 0, width: 0, flex: 'unset', maxWidth: '50%'})
        gsap.set(cta, { scale: 0, autoAlpha: 0,})
        
        tl
            .addLabel('Start')
            .set(ctawrap, { overflow: 'visible', width: '0%'})
            .set(cta, { x: -500, autoAlpha: 0, scale: 0})
            .to(col, { flexGrow: 1, width: 'auto', flex: '0 0 50%'}, 0)
            .to(ctawrap, 0.2, { width: '100%'}, 0)
            .to(cta, {autoAlpha: 1, scale: 1, stagger: 0.2, }, 0.1)
            .to(cta, {x: 0, stagger: 0.1, ease: 'back.out(2)'}, 0.1)
            .addLabel('Middle')
            .to(cta, {autoAlpha: 0, scale: 0, x: -200 }, 'Middle')
            .to(col, { flexGrow: 0, width: 0, flex: '0 0 0%'}, 'Middle')
            .addLabel('End')

        input.onchange = ()=> {
            input.checked ? tl.seek('Start').tweenTo('Middle') : tl.seek('Middle').tweenTo('End');
        }

    }
}

new EventDay