const match = {
    pin: [2,0,2,3],
    domain: 'pfizer.com',
}

init();

function init(){
    const form = document.querySelector('form#login')
    if(!form) return;

    const 
        email = form.querySelector('#login__email'),
        pin = form.querySelectorAll('.form__pin input'),
        submit = form.querySelector('#login__submit');

    checkform();
    
    email.addEventListener('input', emailValid);
    pin.forEach((ele, index) => {
        ele.addEventListener('input', pinValid)
    })

    function emailValid() {
        const 
            parent = this.closest('.form__input'),
            regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            val = this.value
    
        this.isValid = false;
        parent.classList.remove('valid')
        
        if(regEx.test(val) && val.toLowerCase().includes(match.domain)) {
            this.isValid = true;
            parent.classList.add('valid')
        }

        checkform();
    }
    
    function pinValid() {
        const 
            val = this.value;

        if(isNaN(val)) {
            this.value = '';
            return false;
        }

        if(this.nextElementSibling && this.value !== '') this.nextElementSibling.focus();

        checkform();
    }

    function checkform() {
        const validEmail = email.isValid;
        let pinArr = [];
        pin.forEach((ele, index) => {
            pinArr.push(parseInt(ele.value));
        })

        submit.setAttribute('disabled', '')

        if(validEmail && pinArr.toString() === match.pin.toString()) {
            submit.removeAttribute('disabled')
            submit.focus();
        }

    }
}

// (function ($) {
//     var form = $('form#login'),
//         inputs = form.find('input'),
//         match = [2,0,2,3];

//     function pinIsValid(pinArr, inputs) {
//         var pin = pinArr,
//             arr = [];
//             inputs.each(function(i) { arr.push($(this).val()); });

//         return pin.join() === arr.join()
//     }

//     // Validates email address of course.
//     function emailIsValid(email) {
//         return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
//     }

//     function checkform(form) {
//         var email = form.find('#login__email'),
//             pin = form.find('.form__pin [type="password"]'),
//             submit = form.find('#login__submit');
//         // check the email
//         emailIsValid(email.val()) ? email.closest('.form__input').addClass('valid') : email.closest('.form__input').removeClass('valid')

//         // check the pin
//         pinIsValid(match, pin) ? submit.removeAttr('disabled') : submit.attr('disabled', '');
//     }

//     function nextPin(form) {
//         var pinInputs = form.find('.form__pin input[type="password"]'),
//             index = jQuery.map(pinInputs, function( n, i ) { if($(n).is(':focus')) return (i); }),
//             next = $(pinInputs[index]).next();

//             if (next.length)
//             next.focus();
//     }

//     if(form.length) {
//         inputs.on('keyup', function(e) {
//             checkform(form)
//             nextPin(form)
//         });

//         // to give the impression you are logining in when on the build folder
//         /*
// 		$('#login__submit').on('click', function(e){
//             if (window.location.href.indexOf('html-build') > -1) {
//                 e.preventDefault();
//                 window.location = '/html-build/welcome.php';
//             }
//         })
// 		*/
//     }

    
// })(jQuery);




// // (function($){
// //     $(document).ready(function(){
// //         $('#pinflds input[tabindex=1]').focus();
// //         $('#pinflds input[type=text]').keypress(function(e){
// //             if (e.which !== 13)
// //             {
// //                 let tab = parseInt($(this).attr('tabindex')) + 1;
// //                 if (tab > 4) tab = 1;
// //                 setTimeout(function(){
// //                     $(`#pinflds input[tabindex=${tab}]`).focus();
// //                 }, 25);
// //             }
// //         });
// //     });
// // })(jQuery);